<script setup lang="ts">
import { Card, Button } from "@/components/Ui";
import { onLink } from "@/helpers/events";

const { t } = useI18n();
</script>
<template>
  <Card
    :description-class="`${$style[`root-description`]} ${
      $style[`root-description-${$i18n.locale}`]
    }`"
    :wrapper-class="$style[`root-wrapper`]"
    :class="`${$style[`root`]}`"
  >
    <template #description>
      <span>PST Pulse</span>
      <p>{{ t("block.privateBinChecker.desc_1") }}<br />{{ t("block.privateBinChecker.desc_2") }}</p>
    </template>
    <div :class="$style[`root-content`]">
      <div :class="$style[`root-content-image`]">
        <nuxt-img
          src="/img/block-advanced-bins-v2.svg"
          width="565"
          height="248"
          sizes="sm:100vw md:565px"
          aria-label="advanced bin"
          placeholder
          loading="lazy"
        />
      </div>
      <Button
        :title="$t('btn.checkBin')"
        color="white"
        :class="$style[`root-button`]"
        @click="
          onLink('landing_privat_button-5.1', 'https://pulse.pst.net', {
            // 'cta type': 'button',
            // 'cta location': 'Block:PST Pulse – advanced BIN checker',
            // 'cta text': 'Check BIN',
          })
        "
      />
    </div>
  </Card>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] relative flex flex-col;
  @apply px-[30px] pt-[50px] pb-[30px] lg:px-[50px] lg:pt-[70px] lg:pb-[50px];
  @apply min-h-[500px] xxs:min-h-[600px] lg:min-h-[820px];
  @apply bg-neutral-800;

  &-wrapper {
    @apply w-full relative z-10;
  }

  &-description {
    @apply flex flex-col gap-8 md:gap-12;
    & span {
      @apply text-white font-black leading-[85%] text-[62px] xxs:text-[78px] xs:text-[88px] md:text-[120px] lg:text-[154px];
    }

    & p {
      @apply font-bold text-white;
    }

    & > p {
      @apply text-[38px] xxs:text-[42px] md:text-[56px] lg:text-[62px] xl:text-[70px] leading-none;
    }

    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-bg,
    &-it,
    &-pt,
    &-ua,
    &-zh,
    &-es,
    &-th,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-sw{
      & > p {
        @apply text-[38px] xxs:text-[42px] md:text-[56px] lg:text-[62px] xl:text-[70px] leading-none;
      }
    }
    &-zh {
      & > p {
        @apply leading-[1.1];
      }
    }
    &-tr {
      & > p {
        @apply leading-none text-[22px] xxs:text-[30px] md:text-[56px] lg:text-[62px] xl:text-[70px] leading-[1.2];
      }
    }
    &-vi {
      & > p {
        @apply text-[32px] xxs:text-[40px] md:text-[56px] lg:text-[62px] xl:text-[70px] leading-[1.1];
      }
    }
    &-pl,
    &-de {
      & > p {
        @apply text-[32px] xxs:text-[40px] md:text-[56px] lg:text-[62px] xl:text-[70px] leading-none;
      }
    }
    &-be,
    &-ru {
      & > p {
        @apply text-[34px] xxs:text-[42px] md:text-[56px] lg:text-[62px] xl:text-[70px] leading-none;
      }
    }
    &-fr {
      & > p {
        @apply text-[32px] xxs:text-[42px] md:text-[56px] lg:text-[62px] xl:text-[70px] leading-none;
      }
    }

    &-ja {
      & > p {
        @apply text-[38px] xxs:text-[42px] md:text-[56px] lg:text-[62px] xl:text-[70px] leading-[1.1];
      }
    }
  }

  &-button {
    @apply w-full md:w-fit md:min-w-[240px];
  }

  &-content {
    @apply mt-auto;

    &-image {
      @apply absolute left-0 right-0 bottom-0 flex items-center w-full scale-[1.1] md:scale-[1] md:left-auto;
      @apply translate-y-[-100px] md:translate-y-[10%] lg:translate-y-0 md:translate-x-[36%] lg:translate-x-[22%] xl:translate-x-[32%];
      @apply rtl:md:left-0 rtl:md:-translate-x-[36%] rtl:lg:-translate-x-[22%] rtl:xl:-translate-x-[32%];
      & img {
        @apply w-full md:min-w-[880px] lg:min-w-[1315px];
      }
    }
  }
}
</style>
