<script setup lang="ts">
import { Card } from "@/components/Ui";
const { t } = useI18n();
</script>
<template>
  <Card
    :wrapper-class="$style[`root-wrapper`]"
    :description-class="$style[`root-description`]"
    :class="`${$style[`root`]}`"
  >
    <template #description>
      <span
        :class="`${$style[`root-subtitle`]} ${
          $style[`root-subtitle-${$i18n.locale}`]
        }`"
      >
        {{ t("block.privateCrypto.subtitle") }}
      </span>
      <span
        :class="`${$style[`root-title`]} ${
          $style[`root-title-${$i18n.locale}`]
        }`"
      >
        {{ t("block.privateCrypto.title_1") }} <br />
        {{ t("block.privateCrypto.title_2") }} <br />
        {{ t("block.privateCrypto.title_3") }}
      </span>
    </template>
    <div
      ref="cardFirstImageEl"
      :class="$style[`root-content`]"
    >
      <nuxt-img
        src="/img/crypto-vertical-v2.png"
        class="hidden md:block"
        width="421"
        height="1361"
        format="webp"
        sizes="sm:100vw md:50vw lg:421px"
        aria-label="crypto and more"
        placeholder
        loading="lazy"
      />
      <nuxt-img
        src="/img/crypto-horizontal-v2.png"
        width="340"
        height="310"
        format="webp"
        sizes="sm:100vw md:50vw lg:100px"
        class="md:hidden"
        aria-label="crypto and more"
        placeholder
        loading="lazy"
      />
    </div>
  </Card>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] relative flex flex-col;
  @apply px-[30px] pt-[50px] pb-[30px] lg:px-[50px] lg:pt-[70px] lg:pb-[70px];
  @apply min-h-[500px] xxs:min-h-[600px] lg:min-h-[820px];
  @apply bg-neutral-800;

  &-wrapper {
    @apply h-full w-full;
  }

  &-description {
    @apply flex flex-col gap-8 md:gap-12 lg:gap-16 h-full md:justify-center;
  }

  &-button {
    @apply md:min-w-[240px] mt-auto w-full md:w-fit;
  }

  &-title {
    @apply leading-[100%] xs:leading-[85%] font-black text-white;
    @apply text-[62px] xxs:text-[72px] xs:text-[88px] md:text-[110px] lg:text-[152px] xl:text-[172px];
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-bg,
    &-de,
    &-it,
    &-pt,
    &-zh,
    &-th,
    &-hi,
    &-bn,
    &-ko,
    &-sw {
      @apply text-[62px] xxs:text-[72px] xs:text-[88px] md:text-[110px] lg:text-[152px] xl:text-[172px];
    }
    &-th {
      @apply leading-[1.1];
    }
    &-hi {
      @apply leading-[1.16];
    }
    &-ko,
    &-zh {
      @apply leading-[1.2];
    }
    &-pl,
    &-be,
    &-ua {
      @apply text-[50px] xxs:text-[62px] xs:text-[74px] md:text-[102px] lg:text-[142px] xl:text-[172px];
    }
    &-tr {
      @apply leading-none text-[42px] xxs:text-[54px] xs:text-[64px] md:text-[92px] lg:text-[122px] xl:text-[142px] md:pr-24;
    }
    &-ar {
      @apply text-[62px] xxs:text-[72px] xs:text-[88px] md:text-[110px] lg:text-[152px] xl:text-[170px] md:max-w-[calc(100%-300px)];
    }
    &-vi {
      @apply text-[52px] xxs:text-[64px] xs:text-[76px] md:text-[84px] lg:text-[108px] xl:text-[132px];
    }
    &-ru {
      @apply text-[52px] xxs:text-[64px] xs:text-[76px] md:text-[100px] lg:text-[138px] xl:text-[172px];
    }

    &-fr {
      @apply text-[62px] xxs:text-[72px] xs:text-[88px] md:text-[110px] lg:text-[152px] xl:text-[172px];
    }

    &-es {
      @apply leading-none text-[62px] xxs:text-[72px] xs:text-[88px] md:text-[110px] lg:text-[152px] xl:text-[172px];
    }

    &-ja {
      @apply leading-[1.1] text-[56px] xxs:text-[70px] xs:text-[80px] md:text-[110px] lg:text-[150px] xl:text-[172px];
    }
  }

  &-subtitle {
    @apply text-accent-primary leading-none font-semibold;
    @apply text-[22px] xxs:text-[28px] xs:text-[32px] lg:text-[37px] xl:text-[47px];
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-be,
    &-bg,
    &-de,
    &-it,
    &-pt,
    &-tr,
    &-th,
    &-hi,
    &-ua,
    &-zh,
    &-ru,
    &-pl,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-vi,
    &-sw{
      @apply text-[22px] xxs:text-[28px] xs:text-[32px] lg:text-[37px] xl:text-[47px];
    }

    &-fr {
      @apply text-[20px] xxs:text-[24px] xs:text-[28px] lg:text-[37px] xl:text-[47px];
    }

    &-es {
      @apply text-[18px] xxs:text-[24px] xs:text-[28px] lg:text-[37px] xl:text-[47px];
    }

    &-ja {
      @apply text-[22px] xxs:text-[28px] xs:text-[32px] lg:text-[37px] xl:text-[47px];
    }
  }

  &-content {
    @apply md:absolute bottom-0 md:bottom-auto left-auto right-auto ltr:md:right-0 md:top-0 md:h-full;
    @apply translate-y-[70px]  md:translate-y-0;
    @apply rtl:md:right-auto rtl:md:left-0;
    & img {
      @apply w-full md:w-[197px] md:w-[220px] lg:w-[300px];
    }
  }
}
</style>
