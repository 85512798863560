<script setup lang="ts">
const { t } = useI18n();
</script>
<template>
  <div :class="$style.root">
    <div :class="$style.value">100</div>
    <div :class="[$style.title, $style[`title-${$i18n.locale}`]]">
      {{ t('block.heroPrivate.freeCardsTitle') }}
    </div>
    <p :class="$style.description">
      {{ t("block.heroPrivate.freeCardsDescription") }}
    </p>
  </div>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] relative flex flex-col items-start w-full overflow-hidden w-full;
  @apply px-[30px] pt-[50px] pb-[30px] lg:px-[50px] lg:pt-[70px] lg:pb-[50px];
  @apply md:min-h-[268px] lg:min-h-[448px];
  background: linear-gradient(330deg, rgba(255, 255, 255, 0.00) 36.6%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(0deg, #282B2F 0%, #282B2F 100%), #F1F2F4;
}

.value {
  @apply text-[134px] xxs:text-[162px] 414:text-[186px] md:text-[172px] lg:text-[210px] font-extrabold leading-[0.85] pb-[14px] w-full;
  letter-spacing: -8px;
  background: linear-gradient(278deg, rgba(0, 0, 0, 0.60) -10.94%, rgba(255, 255, 255, 0.65) 48.99%, rgba(255, 255, 255, 0.28) 105.3%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.description {
  @apply text-accent-primary text-[22px] xxs:text-[24px];
}

.title {
  @apply font-semibold leading-none mb-5 z-10 w-full pb-[65px];
  background: linear-gradient(278deg, rgba(0, 0, 0, 0.60) -10.94%, rgba(255, 255, 255, 0.65) 48.99%, rgba(255, 255, 255, 0.28) 105.3%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
  &-be,
  &-bg,
  &-it,
  &-ua,
  &-zh,
  &-de,
  &-bg,
  &-ru,
  &-ja,
  &-th,
  &-pl,
  &-hi,
  &-ar,
  &-bn,
  &-ko,
  &-vi,
  &-sw {
    @apply text-[24px] xxs:text-[36px];
  }

  &-tr {
    @apply text-[24px] xxs:text-[32px];
  }

  &-zh,
  &-ja {
    @apply leading-[1.2];
  }

  &-pt,
  &-fr,
  &-es {
    @apply text-[24px] xxs:text-[30px] 414:text-[32px] lg:text-[28px] xl:text-[32px];
  }
}
</style>
